<template>
    <div class="rounded rounded-circle scratch-case" :class="forme">
        <vue-scratchable v-slot="{ init }" :brushOptions="brush" :hideOptions="hide">
            <div class="wrapper">
                <img class="mw-100" :src="url" @load="init()" />
            </div>
        </vue-scratchable>
    </div>
</template>

<script>
import VueScratchable from "vue-scratchable";
import paperPattern from "../../assets/img/natural-paper-texture.jpg";

export default {
    components: {
        VueScratchable,
    },
    props: {
        url: null,
        forme: null,
    },
    data: function () {
        return {
            hide: {
                type: "pattern",
                src: paperPattern,
                repeat: "repeat",
            },
            brush: {
                size: 40,
                shape: "round",
            },
        };
    },
};
</script>
