import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import auth from "../services/auth";

import Home from "../components/Home";
import Login from "../components/Login";
import Calendrier from "../components/Calendrier";
import EditSection from "../components/EditSection";
import Case from "../components/Case";
import CaseEditor from "../components/CaseEditor";
import Register from "../components/Register";
import ForgottenPassword from "../components/ForgottenPassword";
import ReinitializePassword from "../components/ReinitializePassword";
import PhotosResults from "../components/PhotosResults";
import _404 from "../components/_404";

function requireAuth(to, from, next) {
    if (!auth.getIfLoggedIn()) {
        next({
            name: "login",
            query: { redirect: to.fullPath },
        });
    } else {
        next();
    }
}

const router = new VueRouter({
    mode: "history",
    base: __dirname,
    routes: [
        {
            path: "/",
            name: "home",
            component: Home,
        },
        {
            path: "/login",
            name: "login",
            component: Login,
            beforeEnter(to, from, next) {
                if (auth.getIfLoggedIn()) {
                    next({ name: "home" });
                } else {
                    next();
                }
            },
        },
        {
            path: "/register",
            name: "register",
            component: Register,
            beforeEnter(to, from, next) {
                if (auth.getIfLoggedIn()) {
                    next({ name: "home" });
                } else {
                    next();
                }
            },
        },
        {
            path: "/forgotten-password",
            name: "forgotten-password",
            component: ForgottenPassword,
            beforeEnter(to, from, next) {
                if (auth.getIfLoggedIn()) {
                    next({ name: "home" });
                } else {
                    next();
                }
            },
        },
        {
            path: "/reinitialize-password",
            name: "reinitialize-password",
            component: ReinitializePassword,
            props: route => ({ token: route.query.t }),
            beforeEnter(to, from, next) {
                if (auth.getIfLoggedIn()) {
                    next({ name: "home" });
                } else {
                    next();
                }
            },
        },
        {
            path: "/calendrier",
            name: "calendrier",
            component: Calendrier,
            beforeEnter: requireAuth,
        },
        {
            path: "/calendrier/case-ouverte",
            name: "case",
            component: Case,
            beforeEnter: requireAuth,
            props: route => ({ lang: route.query.lang }),
        },
        {
            path: "/calendrier/case-editor/:day",
            name: "case-editor",
            component: CaseEditor,
            props: route => ({ lang: route.query.lang }),
            beforeEnter(to, from, next) {
                if (auth.getIfIsAdmin()) {
                    next();
                } else {
                    next({ name: "home" });
                }
            },
        },
        {
            path: "/calendrier/case-editor/:day/edit-contenu-:index/:id?",
            name: "edit-contenu",
            component: EditSection,
            props: route => ({ lang: route.query.lang }),
            beforeEnter(to, from, next) {
                if (auth.getIfIsAdmin()) {
                    next();
                } else {
                    next({ name: "home" });
                }
            },
        },
        {
            path: "/calendrier/photo-result/:day",
            name: "photo-result",
            component: PhotosResults,
            beforeEnter(to, from, next) {
                if (auth.getIfIsAdmin()) {
                    next();
                } else {
                    next({ name: "home" });
                }
            },
        },
        {
            path: "/404-not-found",
            name: "404",
            component: _404,
        },
        {
            path: "/logout",
            beforeEnter(to, from, next) {
                auth.logout();
                next("/");
            },
        },
        {
            path: "*",
            redirect: { name: "404" },
        },
    ],
});

export default router;
