<template>
    <div class="opened-case flex-fill position-relative">
        <div class="content d-flex-center flex-fill h-100" v-if="contenu">
            <div
                class="d-flex-center flex-column my-2 my-md-5 p-2 rounded-sm shadow-lg text-center"
                style="min-width: 600px; max-width: 800px"
            >
                <h1 class="mt-3 mb-2 text-white label-date">
                    <span class="me-3">{{ id ? "Modification" : "Création" }} de la section</span>
                    <img
                        v-if="lang == 'fr'"
                        style="max-height: 50px; max-width: 50px"
                        src="../assets/img/geotec/languages/fr.png"
                    />
                    <img
                        v-if="lang == 'it'"
                        style="max-height: 50px; max-width: 50px"
                        src="../assets/img/geotec/languages/it.png"
                    />
                    <img
                        v-if="lang == 'nl'"
                        style="max-height: 50px; max-width: 50px"
                        src="../assets/img/geotec/languages/nl.png"
                    />
                </h1>

                <div class="d-flex flex-column align-items-center w-100">
                    <div class="row">
                        <div class="col-auto text-white d-flex align-items-center">
                            <label>Type de la section :</label>
                        </div>
                        <div class="col">
                            <div
                                class="col-auto col-sm d-flex flex-column flex-sm-row align-items-sm-center align-items-stretch justify-content-sm-end justify-content-center"
                            >
                                <button
                                    v-for="type in types"
                                    v-bind:key="type.value"
                                    type="button"
                                    class="btn mx-sm-2 my-1 text-nowrap"
                                    v-bind:class="[selectedType === type.value ? 'btn-white' : 'btn-outline-white']"
                                    @click="select_type(type.value)"
                                    :disabled="!!id"
                                >
                                    {{ type.text }}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div id="editors" class="align-self-start w-100 my-3">
                        <div v-if="selectedType == 'text'">
                            <VueEditor class="bg-white" v-model="texte" />
                        </div>

                        <div class="text-start text-white" v-else-if="selectedType == 'video'">
                            <div class="mb-3">
                                <label for="url_youtube" class="form-label">Url de la vidéo YouTube :</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="url_youtube"
                                    v-model="urlYoutube"
                                    placeholder="https://www.youtube.com/embed/[ID_VIDEO]"
                                />
                                <small>
                                    ID_VIDEO : identifiant de la vidéo YouTube que vous pouvez retrouver dans l'url de
                                    votre vidéo
                                </small>
                            </div>

                            <div
                                class="w-100 mt-2 text-white small cursor-pointer text-left"
                                @click="urlYoutube = 'https://www.youtube.com/embed/'"
                            >
                                Cliquer pour préremplir <u>https://www.youtube.com/embed/</u>
                            </div>
                        </div>

                        <div class="text-start text-white" v-else-if="selectedType == '2v1m'">
                            <div
                                class="border border-2 rounded p-3 mt-3"
                                :class="mensonge == 1 ? 'border-danger' : 'border-success'"
                            >
                                <div class="mb-3">
                                    <label for="affirmation1_text" class="form-label">Affirmation 1</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="affirmation1_text"
                                        v-model="myparams.affirmation1_text"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label for="affirmation1_reponse" class="form-label">Réponse 1</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="affirmation1_reponse"
                                        v-model="myparams.affirmation1_reponse"
                                    />
                                </div>
                            </div>
                            <div
                                class="border border-2 rounded p-3 mt-3"
                                :class="mensonge == 2 ? 'border-danger' : 'border-success'"
                            >
                                <div class="mb-3">
                                    <label for="affirmation2_text" class="form-label">Affirmation 2</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="affirmation2_text"
                                        v-model="myparams.affirmation2_text"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label for="affirmation2_reponse" class="form-label">Réponse 2</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="affirmation2_reponse"
                                        v-model="myparams.affirmation2_reponse"
                                    />
                                </div>
                            </div>
                            <div
                                class="border border-2 rounded p-3 mt-3"
                                :class="mensonge == 3 ? 'border-danger' : 'border-success'"
                            >
                                <div class="mb-3">
                                    <label for="affirmation3_text" class="form-label">Affirmation 3</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="affirmation3_text"
                                        v-model="myparams.affirmation3_text"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label for="affirmation3_reponse" class="form-label">Réponse 3</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="affirmation3_reponse"
                                        v-model="myparams.affirmation3_reponse"
                                    />
                                </div>
                            </div>
                            <div class="d-flex-center my-3">
                                <span class="me-3 fs-5">Mensonge :</span>
                                <div>
                                    <button
                                        class="btn btn-lg mx-sm-2 my-1 text-nowrap"
                                        style="width: 50px"
                                        v-bind:class="mensonge == '1' ? 'btn-danger' : 'btn-outline-success'"
                                        @click="setMensonge(1)"
                                    >
                                        1
                                    </button>
                                    <button
                                        class="btn btn-lg mx-sm-2 my-1 text-nowrap"
                                        style="width: 50px"
                                        v-bind:class="mensonge == '2' ? 'btn-danger' : 'btn-outline-success'"
                                        @click="setMensonge(2)"
                                    >
                                        2
                                    </button>
                                    <button
                                        class="btn btn-lg mx-sm-2 my-1 text-nowrap"
                                        style="width: 50px"
                                        v-bind:class="mensonge == '3' ? 'btn-danger' : 'btn-outline-success'"
                                        @click="setMensonge(3)"
                                    >
                                        3
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <router-link
                            tag="button"
                            class="btn btn-normal text-white my-3"
                            :to="{ name: 'case-editor', params: { day }, query: { lang } }"
                        >
                            <i class="fa-duotone fa-solid fa-xmark"></i>
                            <span class="ms-2">Annuler</span>
                        </router-link>
                        <button @click="saveSection" class="btn btn-geotec text-white ms-3">
                            <i class="fa-duotone fa-solid fa-check"></i>
                            <span class="ms-2">Enregistrer</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="content w-100 position-absolute" v-if="!contenu" style="top: 35%">
            <div class="d-flex-center flex-column my-2 my-md-5 p-2 rounded-sm shadow-lg mw-100 text-center">
                <h1 class="mt-3 mb-2 label-date">Un peu de patience...</h1>
            </div>
        </div>

        <div class="x" v-if="!contenu">
            <img class="y" src="@/assets/img/gift.png" alt="codepen" />
        </div>
    </div>
</template>

<script>
import router from "../services/router";
import auth from "../services/auth";
import loader from "../services/loader";
import { getSectionContent, saveSectionContent } from "../services/cases-service";
import { VueEditor } from "vue2-editor";

const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export default {
    components: {
        VueEditor,
    },
    props: {
        lang: null,
    },
    data: function () {
        const day = this.$route.params.day;
        const id = this.$route.params.id;
        const ordre = this.$route.params.index;
        const currentDate = new Date(Date.UTC(2024, 11, day, 0, 0, 0));

        const options = {
            weekday: "long",
            month: "long",
            day: "numeric",
        };

        return {
            currentDate: capitalize(currentDate.toLocaleDateString("fr-FR", options)),
            day,
            id,
            contenu: null,
            types: [],
            ordre,
            selectedType: null,
            urlYoutube: null,
            texte: null,
            urlImage: null,
            myparams: {},
            mensonge: null,
        };
    },
    methods: {
        select_type: function (selection) {
            this.selectedType = selection;
        },
        setMensonge(num) {
            this.mensonge = num;
        },
        async saveSection() {
            loader.showLoader();

            const contenu = {
                id: this.id,
                ordre: +this.ordre,
                jour: +this.day,
                type: this.selectedType,
                valeur: "",
                url: "",
            };

            switch (contenu.type) {
                case "text":
                    contenu.valeur = this.texte;
                    break;
                case "video":
                    contenu.url = this.urlYoutube;
                    break;
                case "2v1m":
                    var _params = this.myparams;
                    _params.mensonge = this.mensonge;
                    contenu.params = _params;
                    break;
            }

            await saveSectionContent({ contenu, token: auth.getToken(), lang: this.lang })
                .then((result) => {
                    const success = result.data;
                    if (success) {
                        router.push({
                            name: "case-editor",
                            params: { day: this.day },
                            query: { lang: this.lang },
                        });
                    } else {
                        alert("KO");
                    }
                })
                .catch(function (error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });

            loader.hideLoader();
        },
    },
    asyncComputed: {
        async contenu() {
            var _contenu;

            this.types = [
                { value: "text", text: "Texte" },
                { value: "video", text: "Vidéo YouTube" },
                { value: "photo", text: "Challenge photo" },
                { value: "santa", text: "Santa Game" },
                { value: "memory", text: "Memory" },
                { value: "2v1m", text: "2V 1M" },
            ];

            // si la section existe on la charge depuis la base
            if (this.id) {
                await getSectionContent({ id: this.id, token: auth.getToken(), lang: this.lang })
                    .then((result) => {
                        const data = result.data;
                        _contenu = data;
                    })
                    .catch(function (error) {
                        console.error("Une erreur est survenue", error.code, error.message, error.details);
                    });

                switch (_contenu.type) {
                    case "text":
                        this.texte = _contenu.valeur;
                        break;
                    case "video":
                        this.urlYoutube = _contenu.url;
                        break;
                    case "2v1m":
                        console.log(_contenu);
                        this.myparams = _contenu.params ?? {};
                        this.mensonge = _contenu.params?.mensonge;
                        break;
                }

                this.selectedType = _contenu.type;

                return _contenu;
            } else {
                this.selectedType = "text";

                _contenu = {
                    jour: this.day,
                    ordre: this.ordre,
                    type: "text",
                    valeur: null,
                };
            }

            return _contenu;
        },
    },
};
</script>
