<template>
    <div class="container border-geotec border-4 border-top border-bottom py-3 position-relative">
        <div
            v-if="isLoading"
            class="position-absolute bg-geotec w-100 h-100 d-flex-center"
            style="left: 0; z-index: 9; --bs-bg-opacity: 0.9"
        >
            <div class="loader__box d-flex-center flex-column rounded p-3 pt-5">
                <div class="spinner-border" role="status"></div>
                <span class="mt-4--5 fs-2 santa-font">{{ t.loading }}</span>
            </div>
        </div>
        <div class="w-100 d-flex-center flex-column gap-4--5" v-if="!playedData.played">
            <div class="w-100">
                <h5>
                    {{ t.uploadPhotoLabel }}
                    <span v-if="isPhotoRequired">*</span>
                </h5>
                <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
                    <div class="dropbox rounded">
                        <input
                            type="file"
                            name="photos"
                            :disabled="isSaving"
                            @change="filesChange($event.target.name, $event.target.files)"
                            accept="image/*"
                            class="input-file"
                        />
                        <p style="white-space: pre-wrap" v-if="isInitial">{{ t.uploadPhotoExplain }}</p>
                        <p v-if="isSaving">{{ t.uploadPhotoSaving }}</p>
                    </div>
                </form>

                <!--SUCCESS-->
                <div v-if="isSuccess">
                    <p>
                        <a href="javascript:void(0)" @click="reset()"></a>
                    </p>
                    <div class="d-flex-center flex-column position-relative">
                        <button
                            @click="reset()"
                            class="btn-reset bg-geotec position-absolute btn text-white btn-geotec"
                        >
                            {{ t.uploadPhotoChangeFile }}
                        </button>
                        <img :src="selectedFile.url" style="max-height: 300px" class="img-responsive img-thumbnail" />
                    </div>
                </div>
                <!--FAILED-->
                <div v-if="isFailed" class="d-flex-center flex-column">
                    <h2>{{ t.failUploadPhotoMsg }}</h2>
                    <button @click="reset()" class="btn text-white btn-geotec">
                        {{ t.tryAgain }}
                    </button>
                    <pre>{{ uploadError }}</pre>
                </div>
            </div>

            <div class="w-100">
                <h5>
                    {{ t.uploadPhotoDescriptionLabel }}
                    <span v-if="isDescriptionRequired">*</span>
                </h5>
                <textarea
                    class="form-control"
                    id="description"
                    name="description"
                    v-model="selectedFile.description"
                    rows="5"
                ></textarea>
            </div>

            <button class="btn btn-geotec text-white" @click="sendPhoto">
                <i class="fa-solid fa-check"></i>
                <span class="ms-2">{{ t.uploadPhotoBtnSend }}</span>
            </button>
        </div>

        <div class="w-100 d-flex-center flex-column gap-4--5" v-else>
            <div class="w-100 d-flex-center flex-column" v-if="playedData.url">
                <h5 class="w-100">{{ t.uploadPhotoLabel }}</h5>
                <img :src="playedData.url" style="max-height: 300px" class="img-responsive img-thumbnail" />
            </div>

            <div class="w-100" v-if="playedData.description">
                <h5>{{ t.uploadPhotoDescriptionLabel }}</h5>
                <p style="white-space: pre-wrap" class="text-white">{{ playedData.description }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getFileDataUrl, updloadPhoto, getIfUpdloadPhoto } from "../../services/file-upload-service";
import auth from "../../services/auth";
import { t } from "../../locale/language";
import modalHelper from "../../services/modal-helper";
import { saveUserPlaying } from "../../services/cases-service";

const STATUS_INITIAL = 0,
    STATUS_SAVING = 1,
    STATUS_SUCCESS = 2,
    STATUS_FAILED = 3;

export default {
    props: {
        day: { type: Number },
        isPhotoRequired: { type: Boolean, default: true },
        isDescriptionRequired: { type: Boolean, default: false },
    },
    data() {
        return {
            selectedFile: {
                url: null,
                file: null,
                description: "",
            },
            uploadError: null,
            currentStatus: null,
            t,
            playedData: { played: false },
            isLoading: false,
        };
    },
    computed: {
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        },
    },
    methods: {
        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFiles = [];
            this.uploadError = null;
        },
        filesChange(fieldName, fileList) {
            // upload data to the server
            this.currentStatus = STATUS_SAVING;

            if (!fileList.length) return;

            const file = event.target.files[0];
            getFileDataUrl(file)
                .then((url) => {
                    this.selectedFile.url = url;
                    this.selectedFile.file = file;
                    this.currentStatus = STATUS_SUCCESS;
                })
                .catch((error) => {
                    console.error("Error reading the file:", error);
                    this.uploadError = error.response;
                    this.currentStatus = STATUS_FAILED;
                });
        },
        async sendPhoto() {
            if (this.isPhotoRequired && !this.selectedFile.url) {
                modalHelper.showModal(this.t.uploadMsgPhotoRequired);
                return;
            }
            if (this.isDescriptionRequired && !this.selectedFile.description) {
                modalHelper.showModal(this.t.uploadMsgDescriptionRequired);
                return;
            }

            this.showLoader();

            var extension = "";
            if (this.selectedFile.file) {
                var parts = this.selectedFile.file?.name?.split(".");
                extension = parts[parts.length - 1];
            }
            const token = auth.getToken();
            const params = {
                token,
                day: this.day,
                base64ImageString: this.selectedFile.url,
                description: this.selectedFile.description,
                fileExtension: extension,
            };

            await updloadPhoto(params)
                .then((result) => {
                    const data = result.data;
                    if (data.isSuccess) {
                        this.playedData = data.playedData;
                    } else {
                        console.log(data.error);
                    }
                })
                .catch(function (error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });

            saveUserPlaying({ day: this.day, token })
                .then(() => {
                    return;
                })
                .catch(function (error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });

            this.hideLoader();
        },
        showLoader() {
            this.isLoading = true;
        },
        hideLoader() {
            this.isLoading = false;
        },
    },
    async created() {},
    mounted() {
        this.reset();
        this.showLoader();
        const token = auth.getToken();
        const params = {
            token,
            day: this.day,
        };
        getIfUpdloadPhoto(params).then((photo) => {
            this.playedData = photo.data;
            this.hideLoader();
        });
    },
};
</script>

<style lang="scss">
.dropbox {
    outline: 2px dashed white; /* the dash box */
    outline-offset: -10px;
    background: rgb(224, 153, 72);
    color: white;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
}

.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
}

.dropbox:hover {
    background: var(--geotec); /* when mouse over to the drop zone, change color */
}

.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}

.btn-reset {
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
}
</style>
