import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";

const getDayContent = httpsCallable(functions, "geotec_getDayContent");
const deleteSectionContent = httpsCallable(functions, "geotec_deleteSectionContent");
const saveSectionContent = httpsCallable(functions, "geotec_saveSectionContent");
const getSectionContent = httpsCallable(functions, "geotec_getSectionContent");
const saveUserConnection = httpsCallable(functions, "geotec_saveUserConnection");
const saveUserPlaying = httpsCallable(functions, "geotec_saveUserPlaying");
const getNumeroLoterie = httpsCallable(functions, "geotec_getNumeroLoterie");
const sendAnswerTruthsLie = httpsCallable(functions, "geotec_sendAnswerTruthsLie");
const getIfPlayedTruthsLie = httpsCallable(functions, "geotec_getIfPlayedTruthsLie");
const getPhotosResultats = httpsCallable(functions, "geotec_getPhotosResultats");

export {
    getDayContent,
    deleteSectionContent,
    saveSectionContent,
    getSectionContent,
    saveUserConnection,
    saveUserPlaying,
    getNumeroLoterie,
    sendAnswerTruthsLie,
    getIfPlayedTruthsLie,
    getPhotosResultats
};
