<template>
    <div class="container not-found mt-5 pt-5 text-center">
        <h1 style="font-size: 4rem" class="fw-bold text-white">404</h1>
        <h1 style="font-size: 2.5rem" class="fw-bold text-geotec">{{ t.notFountMsg }}</h1>
        <router-link to="/" class="text-decoration-none">{{ t.notFoundBackHome }}</router-link>
    </div>
</template>

<script lang="js">
import { t } from '../locale/language'

export default {
    data() {
        return {
            t,
        };
    },
};
</script>

<style>
body {
    background-color: #212529 !important;
}
</style>
