import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
import cookies from "../plugins/cookies";
import { getLanguage, t, setLanguage } from "../locale/language";

var _isAdmin = null;
const _getIfLoggedIn = () => cookies.exists("token");

export default {
    name: "auth",

    async signin(data, cb) {
        cb = arguments[arguments.length - 1];
        if (_getIfLoggedIn()) {
            if (cb) cb(true);
            this.onChange(true);
            return;
        }

        var params = window.btoa(JSON.stringify(data));

        const result = await httpsCallable(functions, "geotec_signin")(params)
            .catch(function (error) {
                console.log("Une erreur est survenue", error.code, error.message, error.details);
                cb(false);
            });

        const isSuccess = result.data;

        cb(isSuccess);
    },

    async login(email, password, cb) {
        cb = arguments[arguments.length - 1];
        if (_getIfLoggedIn()) {
            if (cb) cb({ authenticated: true });
            this.onChange(true);
            return;
        }
        var params = { email, password };
        var token = window.btoa(JSON.stringify(params));

        // call login function, return token and if user is admin
        const result = await httpsCallable(functions, "geotec_login")(token)
            .catch(function (error) {
                console.log("Une erreur est survenue", error.code, error.message, error.details);
                cb({ authenticated: false, errorMessage: "Une erreur est survenue" });
                this.onChange(false);
            });

        const data = result.data;
        if (data.token) {
            cookies.set("token", data.token);
            cookies.set("isAdmin", data.isAdmin);
            setLanguage(data.lang);

            cb(data);
            this.onChange(true);
        } else {
            cb(data);
            this.onChange(false);
        }
    },

    async forgottenPassword(email, cb) {
        cb = arguments[arguments.length - 1];
        if (_getIfLoggedIn()) {
            if (cb) cb({ authenticated: true });
            return;
        }
        var params = { email, language: getLanguage() };
        var token = window.btoa(JSON.stringify(params));

        const result = await httpsCallable(functions, "geotec_forgotten_password")(token)
            .catch(function (error) {
                console.log("Une erreur est survenue", error.code, error.message, error.details);
                cb({ isSuccess: false, errorMessage: t.anErrorOccurs });
            });

        const data = result.data;
        cb(data);
    },

    async reinitializePassword(params, cb) {
        cb = arguments[arguments.length - 1];
        if (_getIfLoggedIn()) {
            if (cb) cb({ authenticated: true });
            return;
        }
        var token = window.btoa(JSON.stringify(params));

        const result = await httpsCallable(functions, "geotec_reinitialize_password")(token)
            .catch(function (error) {
                console.log("Une erreur est survenue", error.code, error.message, error.details);
                cb({ isSuccess: false, errorMessage: t.anErrorOccurs });
            });
        cb(result.data);
    },

    logout(cb) {
        cookies.remove("token");
        cookies.remove("isAdmin");
        _isAdmin = null;

        if (cb) cb();
        this.onChange(false);
    },

    getToken: () => cookies.get("token"),
    getIfLoggedIn: _getIfLoggedIn,
    getIfIsAdmin: () => {
        if (_isAdmin == null) {
            _isAdmin = JSON.parse(cookies.get("isAdmin"));
        }

        return _isAdmin;
    },
    onChange() { },
};
