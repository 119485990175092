<template>
    <div class="calendrier row g-0 flex-fill" :style="cssVars">
        <div class="snowflakes">
            <div class="snowflake" v-for="i in 50" v-bind:key="i"></div>
        </div>

        <div
            class="case col-3 col-sm-3 col-md-3 col-lg-2 d-flex-center rounded p-1 p-lg-3"
            v-for="day in days"
            v-bind:key="day"
        >
            <button class="btn btn-outline-white w-100 h-100" v-bind:class="dayClass(day)" @click="openCase(day)">
                {{ day }}
            </button>

            <i
                @click="openCaseEditor(day)"
                class="case__edit text-hover-geotec fa-duotone fa-solid fa-gear position-absolute text-geotec fa-lg"
                v-if="isAdmin"
            ></i>
        </div>
    </div>
</template>

<script>
import router from "../services/router";
import { isStarted } from "../services/client";
import auth from "../services/auth";

export default {
    data() {
        let todayDay = new Date().getDate();
        let days = [16, 10, 2, 21, 7, 17, 3, 11, 9, 13, 5, 22, 6, 20, 15, 18, 24, 1, 19, 23, 14, 4, 8, 12];
        let excludeDays = [1, 7, 8, 14, 15, 21, 22];
        let isAdmin = auth.getIfIsAdmin();

        if (isAdmin) {
            days = days.filter((x) => excludeDays.indexOf(x) === -1).sort((a, b) => a - b);
        }

        this.$gtag.event("open_calendar", {
            event_category: "calendar",
            event_label: "geotec",
        });

        return {
            days,
            isAdmin,
            todayDay,
            excludeDays,
            isStarted: isStarted() || isAdmin,
        };
    },
    methods: {
        openCase: function (day) {
            this.$gtag.event("open_case", {
                event_category: "calendar",
                event_label: "geotec",
                value: day,
            });
            router.push({ name: "case", params: { day: day } });
        },
        openCaseEditor: function (day) {
            router.push({ name: "case-editor", params: { day: day } });
        },
        dayClass(_day) {
            var classes = [];
            if (this.isAdmin) classes.push("bg-white");
            else if (this.excludeDays.indexOf(_day) > -1) classes.push("invisible");

            if (this.isStarted && _day < this.todayDay) classes.push("passed");

            return classes.join(" ");
        },
    },
    computed: {
        cssVars() {
            return {
                "--case-opacity": this.isAdmin ? 1 : 0.5,
            };
        },
    },
};
</script>

<style scoped>
body {
    background-color: #212529 !important;
}
</style>
