import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";

function getFileDataUrl(file) {
    return new Promise((resolve, reject) => {
        // Create a FileReader instance
        const reader = new FileReader();

        // Manages file loading
        reader.onload = () => resolve(reader.result);

        // Handle any errors
        reader.onerror = error => reject(error);

        // Read the file as a data URL
        reader.readAsDataURL(file);
    });
}

const updloadPhoto = httpsCallable(functions, "geotec_updloadPhoto");
const getIfUpdloadPhoto = httpsCallable(functions, "geotec_getIfUpdloadPhoto");

export { getFileDataUrl, updloadPhoto, getIfUpdloadPhoto }