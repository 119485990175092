// language.js
import cookies from "../plugins/cookies";
import { ref, computed } from 'vue'
import fr from './fr'
import it from './it'
import nl from './nl'

// Available Languages
const availableLanguages = [
    {
        code: 'fr',
        name: 'Français',
        file: fr,
        locale: "fr-FR"
    },
    {
        code: 'it',
        name: 'Italian',
        file: it,
        locale: "it-IT"
    },
    {
        code: 'nl',
        name: 'Nederland',
        file: nl,
        locale: "nl-Nl"
    },
]

// Current Language Code
const currentLanguageCode = ref('fr')

// Current Language File
const t = computed(
    () => availableLanguages.find((lang) => lang.code === currentLanguageCode.value).file
)

// Set Language
const setLanguage = (code = 'fr') => {
    currentLanguageCode.value = code
    cookies.set('language', code)
}

const getLanguage = () => {
    return cookies.get('language');
}

const getLocale = () => {
    return availableLanguages.find((lang) => lang.code === currentLanguageCode.value).locale;
}

export {
    availableLanguages,
    currentLanguageCode,
    t,
    setLanguage,
    getLanguage,
    getLocale
}