<template>
    <div class="rounded d-flex flex-column gap-3">
        <div class="text-center">
            <h4 class="mb-0 text-geotec fw-bold">{{ t.selectLieTilte }}</h4>
            <small class="mt-0">{{ t.selectLieSubtitle }}</small>
        </div>
        <div
            @click="selectMensonge(1)"
            class="border rounded p-3 cursor-pointer"
            :class="selectedItem === 1 ? 'border-geotec border-4' : 'border-gray-6 border-1'"
        >
            <div>{{ data.affirmation1_text }}</div>
            <div v-if="data.mensonge" class="fw-bold" :class="data.mensonge === 1 ? 'text-danger' : 'text-success'">
                {{ data.affirmation1_reponse }}
            </div>
        </div>
        <div
            @click="selectMensonge(2)"
            class="border rounded p-3 cursor-pointer"
            :class="selectedItem === 2 ? 'border-geotec border-4' : 'border-gray-6 border-1'"
        >
            <div>{{ data.affirmation2_text }}</div>
            <div v-if="data.mensonge" class="fw-bold" :class="data.mensonge === 2 ? 'text-danger' : 'text-success'">
                {{ data.affirmation2_reponse }}
            </div>
        </div>
        <div
            @click="selectMensonge(3)"
            class="border rounded p-3 cursor-pointer"
            :class="selectedItem === 3 ? 'border-geotec border-4' : 'border-gray-6 border-1'"
        >
            <div>{{ data.affirmation3_text }}</div>
            <div v-if="data.mensonge" class="fw-bold" :class="data.mensonge === 3 ? 'text-danger' : 'text-success'">
                {{ data.affirmation3_reponse }}
            </div>
        </div>

        <div class="text-center" v-if="!playedData.played">
            <button class="btn btn-geotec text-white" @click="sendAnswer">
                <i class="fa-solid fa-check"></i>
                <span class="ms-2">{{ t.uploadPhotoBtnSend }}</span>
            </button>
        </div>
        <div v-else class="d-flex-center">
            <div v-if="selectedItem == data.mensonge" class="bg-success p-2 rounded fs-4">{{ t.goodAnswer }} !</div>
            <div v-else class="bg-danger p-2 rounded fs-4">{{ t.badAnswer }} :(</div>
        </div>
    </div>
</template>

<script>
import { getLanguage, t } from "../../locale/language";
import auth from "../../services/auth";
import modalHelper from "../../services/modal-helper";
import { sendAnswerTruthsLie, getIfPlayedTruthsLie, saveUserPlaying } from "../../services/cases-service";

export default {
    components: {},
    props: {
        day: { type: Number },
        data: null,
        sectionGuid: null,
        lang: null,
    },
    data: function () {
        return {
            selectedItem: null,
            t,
            playedData: { played: false },
        };
    },
    methods: {
        selectMensonge(num) {
            if (!this.playedData.played) this.selectedItem = num;
        },
        async sendAnswer() {
            if (!this.selectedItem) {
                modalHelper.showModal(this.t.selectLieRequired);
                return;
            }

            this.showLoader();

            const token = auth.getToken();
            const params = {
                token,
                day: this.day,
                answer: this.selectedItem,
                sectionGuid: this.sectionGuid,
            };
            await sendAnswerTruthsLie(params)
                .then((result) => {
                    const data = result.data;
                    if (data.isSuccess) {
                        this.data = data.params;
                        this.playedData.played = true;
                    } else {
                        console.log(data.error);
                    }
                })
                .catch(function (error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });

            var nbPoints = 1;
            if (this.data.mensonge == this.selectedItem) nbPoints++;

            await saveUserPlaying({ day: this.day, token, points: nbPoints })
                .then(() => {
                    return;
                })
                .catch(function (error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });

            this.hideLoader();
        },
        showLoader() {
            this.isLoading = true;
        },
        hideLoader() {
            this.isLoading = false;
        },
    },
    mounted() {
        this.showLoader();
        const token = auth.getToken();
        const params = {
            token,
            day: this.day,
            sectionGuid: this.sectionGuid,
            lang: this.lang ?? getLanguage(),
        };

        getIfPlayedTruthsLie(params).then((answer) => {
            this.playedData = answer.data;
            if (this.playedData.played) {
                this.data = answer.data.data;
                this.selectedItem = answer.data.answer;
            }
            this.hideLoader();
        });
    },
};
</script>
