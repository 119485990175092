<template>
    <div id="app">
        <div v-if="dev_mode" class="position-fixed" style="top: 10px; left: 10px; z-index: 9999">
            <span class="badge bg-primary d-block d-sm-none">xs</span>
            <span class="badge bg-secondary d-none d-sm-block d-md-none">sm</span>
            <span class="badge bg-success d-none d-md-block d-lg-none">md</span>
            <span class="badge bg-dark d-none d-lg-block d-xl-none">lg</span>
            <span class="badge bg-info d-none d-xl-block">xl</span>
        </div>

        <div class="position-relative flex-fill d-flex flex-column">
            <div v-if="isLoading" class="loader d-flex-center">
                <div class="loader__box d-flex-center flex-column rounded p-3 pt-5">
                    <i class="fa-duotone fa-solid fa-hat-santa fa-bounce fa-2xl"></i>
                    <span class="mt-4--5 fs-4 fw-bold">{{ t.loading }}..</span>
                </div>
            </div>
            <div v-if="displayModal" class="modal d-flex-center px-2">
                <div style="width: 400px" class="modal__box d-flex-center flex-column rounded p-3">
                    <h5 class="text-center text-geotec fw-bold mb-3">
                        <small>{{ t.modalInfoTitle }}</small>
                    </h5>

                    <div style="white-space: pre-wrap">
                        {{ modalContent }}
                    </div>

                    <div class="d-flex align-items-center mt-3">
                        <button @click="closeModal" class="btn btn-sm btn-geotec mt-3">
                            {{ t.close }}
                        </button>
                    </div>
                </div>
            </div>

            <template v-if="$route.matched.length">
                <nav class="navbar navbar-dark navbar-expand-md">
                    <div class="container-fluid">
                        <router-link to="/" class="navbar-brand">
                            <img src="./assets/img/geotec/logo.png" alt="Logo Geotec" />
                        </router-link>
                        <button
                            class="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNavAltMarkup"
                            aria-controls="navbarNavAltMarkup"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div class="navbar-nav ms-end align-items-center">
                                <router-link to="/" class="nav-link" aria-label="ACCUEIL">
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        {{ t.menuAccueil }}
                                    </span>
                                </router-link>
                                <router-link to="/calendrier" class="nav-link" aria-label="CALENDRIER">
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        {{ t.menuCalendrier }}
                                    </span>
                                </router-link>
                            </div>
                            <div
                                v-if="isLoggedIn && isAdmin"
                                class="navbar-nav m-auto align-items-center border p-2 pb-0 py-md-0 rounded"
                            >
                                <span class="badge bg-light text-dark">ADMIN</span>
                                <router-link :to="`/calendrier/photo-result/3`" class="nav-link" aria-label="CONNEXION">
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        Challenge Décorations
                                    </span>
                                </router-link>
                                 <span class="text-white">|</span>
                                 <router-link :to="`/calendrier/photo-result/9`" class="nav-link" aria-label="CONNEXION">
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        Challenge Souvenir
                                    </span>
                                </router-link>
                            </div>
                            <div class="navbar-nav ms-auto align-items-center">
                                <router-link v-if="!isLoggedIn" to="/register" class="nav-link" aria-label="CONNEXION">
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        {{ t.menuInscription }}
                                    </span>
                                </router-link>
                                <router-link v-if="!isLoggedIn" to="/login" class="nav-link" aria-label="CONNEXION">
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        {{ t.menuConnexion }}
                                    </span>
                                </router-link>
                                <router-link v-if="isLoggedIn" to="/logout" class="nav-link" aria-label="DÉCONNEXION">
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        {{ t.menuDeconnexion }}
                                    </span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </nav>

                <router-view ref="view" class="flex-fill body-container"></router-view>
            </template>
            <template v-else-if="isLoggedIn">
                <NotFound />
            </template>
        </div>
    </div>
</template>

<script>
import auth from "./services/auth";
import loader from "./services/loader";
import modalHelper from "./services/modal-helper";
import NotFound from "./components/_404";
import { isStarted } from "./services/client";
import { t } from "./locale/language";

export default {
    components: {
        NotFound,
    },
    data: () => {
        return {
            t,
            isLoggedIn: auth.getIfLoggedIn(),
            isAdmin: auth.getIfIsAdmin(),
            isStarted: isStarted(),
            isLoading: false,
            dev_mode: process.env.NODE_ENV == "development",
            modalContent: "",
            displayModal: false,
        };
    },
    methods: {
        closeModal() {
            modalHelper.hideModal();
        },
    },
    created() {
        auth.onChange = (isLoggedIn) => {
            this.isLoggedIn = isLoggedIn;
            this.isAdmin = auth.getIfIsAdmin();
        };
        loader.showLoader = () => (this.isLoading = true);
        loader.hideLoader = () => (this.isLoading = false);
        loader.isLoading = () => this.isLoading;
        modalHelper.showModal = (modalContent) => {
            this.modalContent = modalContent;
            this.displayModal = true;
        };
        modalHelper.hideModal = () => {
            this.modalContent = "";
            this.displayModal = false;
        };
    },
};
</script>
