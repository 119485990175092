<template>
    <div class="register container-fluid flex-fill d-flex-center py-3 mt-5--5">
        <div v-if="displayModal" class="modal d-flex-center px-2">
            <div v-if="!isSuccess" style="width: 400px" class="modal__box d-flex-center flex-column rounded p-3">
                <h5 class="text-center">
                    <small>{{ t.signinMsgError }}</small>
                </h5>
                <h5 class="text-center" v-if="serverErrorMessage">
                    <small>{{ serverErrorMessage }}</small>
                </h5>

                <div class="d-flex align-items-center mt-3">
                    <button class="btn btn-danger" @click="closeModal()">
                        <i class="fa-solid fa-xmark"></i>
                        <span class="ms-2">{{ t.close }}</span>
                    </button>
                </div>
            </div>
            <div
                v-else-if="form_data.dont_have_pro_email"
                style="width: 400px"
                class="modal__box d-flex-center flex-column rounded p-3"
            >
                <h5 class="text-center">
                    <small>{{ t.signinMsgSuccess }}</small>
                </h5>
                <h5 class="text-center">
                    <small>{{ t.signinMsgSuccessWaitValidation }}</small>
                </h5>
                <h5 class="text-center">
                    <small>{{ t.signinMsgSeeYouLater }}</small>
                </h5>

                <div class="d-flex align-items-center mt-3">
                    <router-link @click="closeModal()" class="btn btn-geotec mt-3" to="/">
                        {{ t.gotit }}
                    </router-link>
                </div>
            </div>
            <div v-else style="width: 400px" class="modal__box d-flex-center flex-column rounded p-3">
                <h5 class="text-center">
                    <small>{{ t.signinMsgSuccess }}</small>
                </h5>
                <!-- <h5 class="text-center">
                    <small>{{ t.signinMsgCanConnect }}</small>
                </h5> -->
                <h5 class="text-center">
                    <small>{{ t.signinMsgSeeYouLater }}</small>
                </h5>

                <div class="d-flex align-items-center mt-3">
                    <router-link @click="closeModal()" class="btn btn-geotec mt-3" to="/">
                        <!-- {{ t.connect }} -->
                        {{ t.gotit }}
                    </router-link>
                </div>
            </div>
        </div>

        <div class="form-signin my-3">
            <form method="POST" class="needs-validation" novalidate>
                <router-link to="/">
                    <img style="max-height: 160px; max-width: 300px" src="../assets/img/geotec/logo.png" class="my-4" />
                </router-link>

                <h1 class="h3 my-3 fw-bold text-geotec text-uppercase">{{ t.signinTitle }}</h1>

                <div class="row g-3">
                    <!-- Language -->
                    <div class="col-12 d-flex-center mb-2 gap-5">
                        <div
                            v-bind:class="[form_data.language === 'fr' ? 'border-geotec' : 'border-transparent']"
                            @click="select_language('fr')"
                            class="border rounded-circle border-5 cursor-pointer"
                        >
                            <img
                                style="max-height: 50px; max-width: 50px"
                                src="../assets/img/geotec/languages/fr.png"
                            />
                        </div>
                        <div
                            v-bind:class="[form_data.language === 'it' ? 'border-geotec' : 'border-transparent']"
                            @click="select_language('it')"
                            class="border rounded-circle border-5 cursor-pointer"
                        >
                            <img
                                style="max-height: 50px; max-width: 50px"
                                src="../assets/img/geotec/languages/it.png"
                            />
                        </div>

                        <div
                            v-bind:class="[form_data.language === 'nl' ? 'border-geotec' : 'border-transparent']"
                            @click="select_language('nl')"
                            class="border rounded-circle border-5 cursor-pointer"
                        >
                            <img
                                style="max-height: 50px; max-width: 50px"
                                src="../assets/img/geotec/languages/nl.png"
                            />
                        </div>
                    </div>

                    <!-- Nom -->
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-uppercase"
                                type="text"
                                v-model="form_data.last_name"
                                id="last_name"
                                name="last_name"
                                placeholder="Dupond"
                                required
                            />
                            <label for="last_name">{{ t.signinLastName }}*</label>
                            <div class="invalid-feedback">{{ t.signinLastNameMsgInvalid }}</div>
                        </div>
                    </div>

                    <!-- Prenom -->
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-capitalize"
                                type="text"
                                v-model="form_data.first_name"
                                id="first_name"
                                name="first_name"
                                placeholder="Alain"
                                required
                            />
                            <label for="first_name">{{ t.signinFirstName }}*</label>
                            <div class="invalid-feedback">{{ t.signinFirstNameMsgInvalid }}</div>
                        </div>
                    </div>

                    <!-- Filiale -->
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <select
                                required
                                v-model="form_data.filiale"
                                class="form-select"
                                aria-label="Fonction"
                                id="filiale"
                                name="filiale"
                            >
                                <option value="A2EP - Géotec">A2EP - Géotec</option>
                                <option value="Arches Etudes">Arches Etudes</option>
                                <option value="Atemac">Atemac</option>
                                <option value="Sefab">Sefab</option>
                                <option value="Rocca e Terra">Rocca e Terra</option>
                                <option value="ME2i">ME2i</option>
                                <option value="GAIDF">GAIDF</option>
                                <option value="Géotec Afrique">Géotec Afrique</option>
                                <option value="Géotec France">Géotec France</option>
                                <option value="Groupe Géotec">Groupe Géotec</option>
                                <option value="Groupe Verbeke">Groupe Verbeke</option>
                                <option value="Géotec Energie">Géotec Energie</option>
                                <option value="C.S.I">C.S.I</option>
                                <option value="Géotec Guyane">Géotec Guyane</option>
                                <option value="Lacq">Lacq</option>
                            </select>
                            <label for="filiale">{{ t.signinFiliale }}*</label>
                            <div class="invalid-feedback">{{ t.signinFilialeMsgInvalid }}</div>
                        </div>
                    </div>

                    <!-- Site -->
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <select
                                required
                                v-model="form_data.site"
                                class="form-select"
                                aria-label="Fonction"
                                id="site"
                                name="site"
                            >
                                <option value="France UTC+1">France UTC+1</option>
                                <option value="Sénégal UTC+0">Sénégal UTC+0</option>
                                <option value="Guinée UTC+0">Guinée UTC+0</option>
                                <option value="Guyane UTC-3">Guyane UTC-3</option>
                                <option value="Cote d'Ivoire UTC+0">Cote d'Ivoire UTC+0</option>
                                <option value="Nouvelle Calédonie UTC+11">Nouvelle Calédonie UTC+11</option>
                                <option value="Mayotte UTC+3">Mayotte UTC+3</option>
                                <option value="La Réunion UTC+4">La Réunion UTC+4</option>
                            </select>
                            <label for="site">{{ t.signinSite }}*</label>
                            <div class="invalid-feedback">{{ t.signinSiteMsgInvalid }}</div>
                        </div>
                    </div>

                    <!-- Checkbox Pas email pro -->
                    <div class="col-12 ps-3">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                v-model="form_data.dont_have_pro_email"
                                v-on:change="cleanEmailFields"
                                type="checkbox"
                                id="dont_have_pro_email"
                            />
                            <label class="form-check-label text-white" for="dont_have_pro_email">
                                {{ t.signinDontHaveProEmail }}
                            </label>
                        </div>
                    </div>

                    <!-- Email pro -->
                    <div class="input-group" v-if="!form_data.dont_have_pro_email">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="text"
                                v-model="form_data.email_base"
                                id="email_base"
                                name="email_base"
                                placeholder="john.doe"
                                v-bind:required="!form_data.dont_have_pro_email"
                            />
                            <label for="email_base">{{ t.signinEmailBase }}*</label>
                            <div class="invalid-feedback">{{ t.signinEmailMsgEmpty }}</div>
                            <div class="small text-geotec" v-if="emailErrorMessage">{{ emailErrorMessage }}</div>
                        </div>
                        <div class="d-sm-none my-1" style="flex-basis: 100%; height: 0"></div>
                        <label class="input-group-text" style="max-height: 58px">@</label>
                        <div class="form-floating flex-grow-0" style="width: 250px">
                            <select
                                v-bind:required="!form_data.dont_have_pro_email"
                                v-model="form_data.email_domain"
                                class="form-select"
                                aria-label="Adresse pro"
                                id="email_domain"
                                name="email_domain"
                                style="width: 250px"
                            >
                                <option value="@geotec.fr">geotec.fr</option>
                                <option value="@a2ep-geotec.nc">a2ep-geotec.nc</option>
                                <option value="@arches-etudes.fr">arches-etudes.fr</option>
                                <option value="@geotechnique-idf.com">geotechnique-idf.com</option>
                                <option value="@geotec-afrique.com">geotec-afrique.com</option>
                                <option value="@verbeke.com">verbeke.com</option>
                                <option value="@me2i.fr">me2i.fr</option>
                                <option value="@rocca-e-terra.com">rocca-e-terra.com</option>
                                <option value="@atemac.fr">atemac.fr</option>
                                <option value="@sefab-france.f">sefab-france.fr</option>
                            </select>
                            <label for="email_domain">{{ t.signinEmailDomain }}*</label>
                            <div class="invalid-feedback">{{ t.signinEmailDomainMsgInvalid }}</div>
                        </div>
                    </div>

                    <!-- Email -->
                    <div class="col-12" v-else>
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="email"
                                v-model="form_data.email"
                                id="email_address"
                                name="email_address"
                                placeholder="nom@exemple.fr"
                                v-bind:required="form_data.dont_have_pro_email"
                            />
                            <label for="email_address">{{ t.signinEmailAddress }}*</label>
                            <div class="invalid-feedback">{{ t.signinEmailMsgEmpty }}</div>
                            <div class="small text-geotec" v-if="emailErrorMessage">{{ emailErrorMessage }}</div>
                        </div>
                    </div>

                    <!-- Mot de passe -->
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-uppercase"
                                type="password"
                                v-model="form_data.password"
                                v-on:input="checkPasswordValidity"
                                id="password"
                                name="password"
                                placeholder="abc123"
                                required
                            />
                            <label for="password">{{ t.signinPassword }}*</label>
                            <div class="invalid-feedback">{{ t.signinPasswordMsgInvalid }}</div>
                            <div class="small text-geotec" v-if="passwordErrorMessage">
                                {{ passwordErrorMessage }}
                            </div>
                        </div>
                    </div>

                    <!-- Confirmation de mot de passe -->
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-uppercase"
                                type="password"
                                v-model="form_data.password_confirmation"
                                v-on:input="checkPasswordValidity"
                                id="password_confirmation"
                                name="password_confirmation"
                                placeholder="abc123"
                                required
                            />
                            <label for="password_confirmation">{{ t.signinPasswordConfirmation }}*</label>
                            <div class="invalid-feedback">{{ t.signinPasswordConfirmationMsgInvalid }}</div>
                        </div>
                    </div>
                </div>

                <button @click="register" class="w-100 btn btn-geotec btn-lg mt-3" type="button">
                    {{ t.validate }}
                </button>
                <div class="m-2 text-white">* {{ t.requiredFields }}</div>
            </form>
        </div>
    </div>
</template>

<script lang="js">
import auth from "../services/auth";
import loader from "../services/loader";
import { t, setLanguage, getLanguage } from '../locale/language'

const empty_form = {
    last_name: "",
    first_name: "",
    email: "",
    email_base: "",
    email_domain: "",
    filiale: "",
    site: "",
    language: "",
    password: "",
    password_confirmation: "",
    dont_have_pro_email: false
};

export default {
    created() {
        this.form_data = Object.assign({}, empty_form);
        this.form_data.language = getLanguage();
    },
    data() {
        return {
            t,
            isSuccess: false,
            displayModal: false,
            passwordErrorMessage: null,
            emailErrorMessage: null,
            serverErrorMessage: null,
            form_data: {
                last_name: "Modin",
                first_name: "Damien",
                email: "damien@innov-events.fr",
                email_base: "damien.modin",
                email_domain:"@geotec.fr",
                filiale: "Atemac",
                site:"Sénégal UTC+0",
                language: getLanguage(),
                password: "poipoi",
                password_confirmation: "poipoi",
                dont_have_pro_email: false
            },
        };
    },
    methods: {
        async register() {
            loader.showLoader();

            if (!this.form_data.dont_have_pro_email) {
                this.form_data.email = `${this.form_data.email_base}${this.form_data.email_domain}`;
            }

            const form = document.querySelector(".needs-validation");
            form.classList.add("was-validated");

            var isFormValid = form.checkValidity();
            isFormValid &= this.checkPasswordValidity();
            isFormValid &= this.checkEmailValidity();

            if (isFormValid) {
                await auth.signin(this.form_data, (response) => {
                    this.isSuccess = response.isSuccess;
                    this.serverErrorMessage = response.errorMessage;
                    this.displayModal = true;
                });
            }

            loader.hideLoader();
        },
        select_language(lang) {
            this.form_data.language = lang;
            setLanguage(lang);
        },
        cleanEmailFields() {
            this.form_data.email_base = this.form_data.email = this.form_data.email_domain = "";
        },
        checkPasswordValidity() {
            if (!this.form_data.password || this.form_data.password.length < 6) {
                this.passwordErrorMessage = this.t.signinPasswordMinChar;
                return false;
            }
            else if (this.form_data.password !== this.form_data.password_confirmation) {
                this.passwordErrorMessage = this.t.signinPasswordNotCorresponding;
                return false;
            }

            this.passwordErrorMessage = null;
            return true;
        },
        checkEmailValidity() {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!this.form_data.email || !re.test(this.form_data.email)) {
                this.emailErrorMessage = this.t.signinEmailAddressMsgInvalid;
                return false;
            }

            this.emailErrorMessage = null;
            return true;
        },
        closeModal() {
            this.displayModal = false;
        }
    },
};
</script>
