<template>
    <div class="login flex-fill d-flex-center py-3">
        <div v-if="displayModal" class="modal d-flex-center px-2">
            <div style="width: 400px" class="modal__box d-flex-center flex-column rounded p-3">
                <h5 class="text-center">
                    <small>{{ t.resetPwdSuccess }}</small>
                </h5>

                <div class="d-flex align-items-center mt-3">
                    <router-link @click="closeModal()" class="btn btn-geotec mt-3" to="/login">
                        {{ t.connect }}
                    </router-link>
                </div>
            </div>
        </div>

        <div class="form-signin">
            <form @submit.prevent="reinitializePassword">
                <h1 @click="fill" class="text-center fw-bold h3 my-3 font-weight-normal text-white text-uppercase">
                    {{ t.reinitializePasswordTitle }}
                </h1>

                <input
                    v-model="password"
                    type="password"
                    class="form-control"
                    :placeholder="t.signinPassword"
                    autofocus
                />
                <input
                    v-model="password_confirmation"
                    type="password"
                    class="form-control"
                    :placeholder="t.signinPasswordConfirmation"
                    autofocus
                />

                <p v-if="serverError" class="text-geotec fw-bold text-center">{{ serverErrorMessage }}</p>

                <button v-bind:class="`btn-geotec`" class="btn btn-lg text-white w-100 mt-2">
                    {{ t.reinitializePasswordLink }}
                </button>

                <p class="mt-3 mb-0 text-gray-2 small">&copy; INNOV'events Dijon - {{ new Date().getFullYear() }}</p>
            </form>
        </div>
    </div>
</template>

<script lang="js">
import auth from "../services/auth";
import loader from "../services/loader";
import { t, getLanguage } from '../locale/language'

export default {
    props: {
        token: null,
    },
    data() {
        return {
            t,
            serverError: false,
            serverErrorMessage:"",
            password: "",
            password_confirmation: "",
            displayModal: false,
        };
    },
    methods: {
        async reinitializePassword() {
            loader.showLoader();

            if (!this.password || this.password.length < 6) {
                this.serverErrorMessage = this.t.signinPasswordMinChar;
                this.serverError = true;
            }
            else if (this.password !== this.password_confirmation) {
                this.serverErrorMessage = this.t.signinPasswordNotCorresponding;
                this.serverError = true;
            }
            else {
                const params = { password: this.password, token: this.token, language: getLanguage() };
                await auth.reinitializePassword(params, (response) => {
                    if (response.isSuccess) {
                        this.displayModal = true;
                    } else {
                        this.serverErrorMessage = response.errorMessage;
                        this.serverError = true;
                    }
                });
            }

            // this.$gtag.event("login", { event_category: "general", event_label: "geotec" });

            loader.hideLoader();
        },
         fill() {
            if (process.env.NODE_ENV == "development") {
                this.password = "poipoi";
                this.password_confirmation = "poipoi";
            }
        },
    },
};
</script>
