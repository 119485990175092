import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCRmKBoJhrXps180fndzd8p0FBEj6mfA14",
    authDomain: "calendrier-noel.firebaseapp.com",
    projectId: "calendrier-noel",
    appId: "1:300418248416:web:30ef4a9f469f3e24efea85",
    measurementId: "G-14GCH33BY0",
};
const app = initializeApp(firebaseConfig);

// const functions = firebase.functions();
// eslint-disable-next-line no-unused-vars
const functions = getFunctions(app, "europe-west1");
if (process.env.NODE_ENV == "development") {
    connectFunctionsEmulator(functions, "127.0.0.1", "5001");
}
// export utils/refs
export { functions };
