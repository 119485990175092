<template>
    <div class="opened-case flex-fill position-relative text-white">
        <div class="rainsequins" v-if="!elements || !isStarted">
            <div class="rainsequin" v-for="i in 500" v-bind:key="i"></div>
        </div>

        <div class="content d-flex-center flex-fill h-100" v-if="elements && isStarted">
            <div
                class="d-flex-center flex-column my-2 my-md-5 p-2 rounded-sm shadow-lg mw-100"
                v-if="isStarted"
                style="width: 700px"
            >
                <h1 class="mt-3 mb-2 label-date">{{ currentDateLabel }}</h1>

                <div v-if="!isTooEarly">
                    <VueEditor class="invisible" style="height: 0px" />

                    <div v-for="element in elements" :key="element.ordre" class="my-3">
                        <div class="ql-snow" v-if="element.type == 'text'">
                            <div v-html="element.valeur" class="text-content ql-editor p-1"></div>
                        </div>

                        <div v-if="element.type == 'video'">
                            <iframe
                                class="video-case my-3"
                                :src="element.url"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </div>

                        <div v-if="element.type == 'santa'" class="position-relative">
                            <div v-if="!isGameStarted" class="position-absolute bg-geotec w-100 h-100 d-flex-center">
                                <button class="btn btn-white text-geotec btn-lg fw-bold" @click="startGame">
                                    {{ t.btnStartGame }}
                                </button>
                            </div>
                            <embed
                                class="santa-case"
                                :src="`https://co.innov.events/santa-jump-geotec-embed`"
                                type="text/html"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            />

                            <div class="text-center">
                                <a
                                    target="_blank"
                                    class="btn btn-geotec"
                                    href="https://co.innov.events/santa-jump-geotec"
                                >
                                    {{ t.santaExternalLink }}
                                </a>
                            </div>
                        </div>

                        <div v-if="element.type == 'memory'" class="position-relative">
                            <div v-if="!isGameStarted" class="position-absolute bg-geotec w-100 h-100 d-flex-center">
                                <button class="btn btn-white text-geotec btn-lg fw-bold" @click="startGame">
                                    {{ t.btnStartGame }}
                                </button>
                            </div>
                            <embed
                                class="memory-case"
                                :src="`https://co.innov.events/geotec-memory-${currentLang()}`"
                                type="text/html"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            />
                        </div>

                        <div v-if="element.type == 'scratch'" class="d-flex-center flex-column">
                            <ScratchCase
                                :url="'https://firebasestorage.googleapis.com/v0/b/calendrier-noel.appspot.com/o/geotec%2Floterie-1.png?alt=media&token=975a08d4-5a14-4afa-88c3-7a94ccb17f2c'"
                                :forme="element.forme"
                            />
                            <br />
                            <ScratchCase
                                :url="'https://firebasestorage.googleapis.com/v0/b/calendrier-noel.appspot.com/o/geotec%2Floterie-2.png?alt=media&token=5c907f95-39f9-4026-86cf-55381d2985c0'"
                                :forme="element.forme"
                            />
                            <br />
                            <ScratchCase
                                :url="'https://firebasestorage.googleapis.com/v0/b/calendrier-noel.appspot.com/o/geotec%2Floterie-3.png?alt=media&token=b25b670e-e187-42bf-bca5-52b76be7e5fd'"
                                :forme="element.forme"
                            />
                            <div class="my-3 d-flex align-items-center" v-if="numeroLoterie > 0">
                                <span class="text-geotec fs-5 fw-bold me-3">{{ t.myLoterieNumber }} : </span>
                                <span class="bg-geotec fs-4 px-3 py-1 rounded fw-bold">{{ numeroLoterie }}</span>
                            </div>
                        </div>

                        <div v-if="element.type == '2v1m'" class="d-flex-center flex-column">
                            <TwoTruthsOneLie
                                :data="element.params"
                                :sectionGuid="element.id"
                                :day="day"
                                :lang="currentLang()"
                            />
                        </div>

                        <div v-if="element.type == 'photo'">
                            <UploadImage
                                :day="day"
                                :isDescriptionRequired="element.isDescriptionRequired"
                                :isPhotoRequired="element.isPhotoRequired"
                            />
                        </div>
                    </div>
                </div>

                <!-- si on est avant le jour selectionne -->
                <TooEarly v-else />
            </div>
        </div>

        <div class="content w-100 position-absolute" v-if="!elements || !isStarted" style="top: 35%">
            <div class="d-flex-center flex-column my-2 my-md-5 p-2 rounded-sm shadow-lg mw-100 text-center">
                <h1 class="mt-3 mb-2 label-date">
                    {{ !isStarted ? t.meetFirstDecember : t.loadingContent }}
                </h1>
            </div>
        </div>

        <div class="x" v-if="!elements || !isStarted">
            <img class="y" src="@/assets/img/paillettes.png" alt="codepen" />
        </div>
    </div>
</template>

<script>
import router from "../services/router";
import auth from "../services/auth";
import { isStarted } from "../services/client";
import { t, getLocale, getLanguage } from "../locale/language";

import { getDayContent, saveUserConnection, saveUserPlaying, getNumeroLoterie } from "../services/cases-service";
import TooEarly from "./TooEarly";
import { VueEditor } from "vue2-editor";
import UploadImage from "./common/UploadImage";
import ScratchCase from "./common/ScratchCase";
import TwoTruthsOneLie from "./common/TwoTruthsOneLie";

const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export default {
    components: {
        TooEarly,
        VueEditor,
        UploadImage,
        ScratchCase,
        TwoTruthsOneLie,
    },
    props: {
        lang: {
            type: String,
            default: null,
        },
    },
    mounted() {
        saveUserConnection({ day: this.day, token: auth.getToken() })
            .then(() => {
                return;
            })
            .catch(function (error) {
                console.error("Une erreur est survenue", error.code, error.message, error.details);
            });

        // this.loadElements();
    },
    data: function () {
        const day = Number(this.$route.params.day || localStorage.day);

        if (!day) {
            router.push({ name: "calendrier" });
            return;
        }

        localStorage.day = day;
        const currentDate = new Date(Date.UTC(2024, 11, day, 0, 0, 0));

        const options = { weekday: "long", month: "long", day: "numeric" };
        const locale = getLocale();

        var currentDateLabel = capitalize(currentDate.toLocaleDateString(locale, options));
        if (day == 1 && getLanguage() === "fr") currentDateLabel = currentDateLabel.replace("1", "1er");

        return {
            t,
            isStarted: isStarted() || auth.getIfIsAdmin(),
            currentDateLabel,
            day,
            elements: null,
            isTooEarly: false,
            isGameStarted: false,
            numeroLoterie: 0,
        };
    },
    methods: {
        currentLang() {
            return this.lang ?? getLanguage();
        },
        async startGame() {
            this.isGameStarted = true;

            saveUserPlaying({ day: this.day, token: auth.getToken() })
                .then(() => {
                    return;
                })
                .catch(function (error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });
        },
    },
    asyncComputed: {
        async numeroLoterie() {
            const day = Number(this.$route.params.day || localStorage.day);
            if (day !== 6) return -1;
            const token = auth.getToken();
            const params = { token };
            return await getNumeroLoterie(params).then((numero) => {
                return numero.data.numeroLoterie;
            });
        },
        async elements() {
            var _elements = [];
            const day = this.$route.params.day || localStorage.day;

            const _lang = this.lang ?? getLanguage();

            await getDayContent({ day, token: auth.getToken(), lang: _lang })
                .then((result) => {
                    const data = result.data;
                    if (data == -1) {
                        this.isTooEarly = true;
                    } else {
                        _elements = data;
                    }
                })
                .catch(function (error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });
            return _elements;
        },
    },
};
</script>
