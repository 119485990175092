<!-- eslint-disable vue/require-v-for-key -->
<template>
    <div class="opened-case flex-fill position-relative text-white">
        <div v-if="displayModal" class="modal d-flex-center mh-100 mw-100">
            <div
                style="max-width: 95%; height: 95%"
                class="modal__box d-flex-center flex-column rounded p-3 position-relative"
            >
                <img :src="zoomed" class="mw-100 mh-100" />
                <button
                    class="position-absolute rounded-circle border border-primary-xlight border-3 btn-sm btn btn-geotec align-self-end text-primary-xlight fw-bold"
                    style="top: -2%; right: -2%"
                    @click="closeZoomModal()"
                >
                    <i class="fa-solid fa-xmark fa-lg"></i>
                </button>
            </div>
        </div>

        <div class="rainsequins" v-if="!elements">
            <div class="rainsequin" v-for="i in 500" v-bind:key="i"></div>
        </div>

        <div class="content d-flex-center flex-fill h-100" v-if="elements">
            <div class="container-lg d-flex-center flex-column my-2 my-md-5 py-2 rounded-sm shadow-lg">
                <h1 class="mt-3 mb-2 label-date">Challenge Jour {{ day }}</h1>

                <div class="row">
                    <template v-for="item in elements">
                        <div class="col-12 text-center my-4">
                            <img src="../assets/img/separation.png" class="mw-100" />
                        </div>
                        <div class="col-12 col-md-4 d-flex-center flex-column text-center">
                            <h4 class="text-geotec">{{ item.user_name }}</h4>
                            <p class="keep-breaks">{{ item.description }}</p>
                            <button class="btn btn-geotec btn-sm mb-3" @click="openZoomModal(item.photo_url)">
                                <i class="fa-duotone fa-regular fa-magnifying-glass-plus fa-lg me-2"></i>
                                <span>Agrandir la photo</span>
                            </button>
                        </div>
                        <div class="col-12 col-md-8 text-center">
                            <img :src="item.photo_url" class="mw-100" style="max-height: 400px" />
                        </div>
                        <div class="col-12"></div>
                    </template>
                </div>
            </div>
        </div>

        <div class="x" v-if="!elements">
            <img class="y" src="@/assets/img/paillettes.png" alt="codepen" />
        </div>
    </div>
</template>

<script>
import router from "../services/router";
import auth from "../services/auth";

import { getPhotosResultats } from "../services/cases-service";

export default {
    components: {},

    data: function () {
        const day = Number(this.$route.params.day || localStorage.day);
        return {
            elements: null,
            day,
            displayModal: false,
            zoomed: null,
        };
    },
    methods: {
        openZoomModal(url) {
            this.zoomed = url;
            this.displayModal = true;
        },
        closeZoomModal() {
            this.zommed = null;
            this.displayModal = false;
        },
    },
    asyncComputed: {
        async elements() {
            var _elements = [];

            await getPhotosResultats({ day: this.day, token: auth.getToken() })
                .then((result) => {
                    const data = result.data;
                    if (data == -1) {
                        router.push({ name: "home" });
                    } else {
                        _elements = data;
                    }
                })
                .catch(function (error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });

            return _elements;
        },
    },
};
</script>
