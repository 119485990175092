<template>
    <div class="home flex-fill text-center d-flex-center position-relative">
        <div class="flex-fill text-center py-4 img-presentation">
            <div class="position-relative">
                <img
                    v-if="language == 'fr'"
                    src="../assets/img/geotec/home2-fr.png"
                    class="img-presentation mt-3 rounded"
                    alt=""
                />
                <img
                    v-else-if="language == 'it'"
                    src="../assets/img/geotec/home2-it.png"
                    class="img-presentation mt-3 rounded"
                    alt=""
                />
                <img
                    v-else-if="language == 'nl'"
                    src="../assets/img/geotec/home2-nl.png"
                    class="img-presentation mt-3 rounded"
                    alt=""
                />
            </div>

            <!-- Language -->
            <div class="d-flex-center">
                <div class="d-flex-center p-4 rounded my-3 gap-5 bg-gray-9 bg-opacity-75">
                    <div
                        v-bind:class="[language === 'fr' ? 'border-white' : 'border-transparent']"
                        @click="select_language('fr')"
                        class="border rounded-circle border-5 cursor-pointer"
                    >
                        <img style="max-height: 50px; max-width: 50px" src="../assets/img/geotec/languages/fr.png" />
                    </div>
                    <div
                        v-bind:class="[language === 'it' ? 'border-white' : 'border-transparent']"
                        @click="select_language('it')"
                        class="border rounded-circle border-5 cursor-pointer"
                    >
                        <img style="max-height: 50px; max-width: 50px" src="../assets/img/geotec/languages/it.png" />
                    </div>

                    <div
                        v-bind:class="[language === 'nl' ? 'border-white' : 'border-transparent']"
                        @click="select_language('nl')"
                        class="border rounded-circle border-5 cursor-pointer"
                    >
                        <img style="max-height: 50px; max-width: 50px" src="../assets/img/geotec/languages/nl.png" />
                    </div>
                </div>
            </div>
            <div>
                <router-link class="btn btn-geotec btn-lg mt-3" to="/calendrier">{{ t.homeOpenCalendar }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { isStarted } from "../services/client";
import { setLanguage, getLanguage, t } from "../locale/language";

export default {
    created() {
        this.language = getLanguage();
    },
    data() {
        return {
            t,
            isStarted: isStarted(),
            language: "",
        };
    },
    methods: {
        select_language(lang) {
            this.language = lang;
            setLanguage(lang);
        },
    },
};
</script>
