<template>
    <div class="login flex-fill d-flex-center py-3">
        <div class="form-signin">
            <form @submit.prevent="login">
                <router-link to="/">
                    <img style="max-height: 200px; max-width: 300px" src="../assets/img/geotec/logo.png" />
                </router-link>

                <h1 @click="fill" class="h3 my-3 font-weight-normal text-white text-uppercase">{{ t.loginTitle }}</h1>

                <input v-model="email" type="text" class="form-control" :placeholder="t.loginEmailAddress" />

                <input v-model="password" type="password" class="form-control" :placeholder="t.loginPassword" />

                <p v-if="serverError" class="text-geotec fw-bold">{{ serverErrorMessage }}</p>

                <button v-bind:class="`btn-geotec`" class="btn btn-lg text-white w-100 mt-2">{{ t.connect }}</button>

                <router-link to="/forgotten-password" class="my-3 text-white text-decoration-none">
                    {{ t.loginForgottenPasswordLink }}
                </router-link>

                <router-link to="/register" class="mb-3 text-white text-decoration-none">
                    {{ t.loginFirstConnectionLink }}
                </router-link>

                <p class="mb-0 text-gray-2 small">&copy; INNOV'events Dijon - {{ new Date().getFullYear() }}</p>
            </form>
        </div>
    </div>
</template>

<script lang="js">
import auth from "../services/auth";
import loader from "../services/loader";
import { t } from '../locale/language'

export default {
    data() {
        return {
            t,
            serverError: false,
            serverErrorMessage:"",
            email: "",
            password:""
        };
    },
    methods: {
        async login() {
            loader.showLoader();

            if (!this.email || !this.password) {
                this.serverErrorMessage = this.t.loginInvalidIdMsgError;
                this.serverError = true;
            }
            else {
                await auth.login(this.email, this.password, (response) => {
                    if (response.authenticated) {
                        this.$router.replace(this.$route.query.redirect || "/");
                    } else {
                        this.serverErrorMessage = response.errorMessage;
                        this.serverError = true;
                    }
                });
            }

            // this.$gtag.event("login", { event_category: "general", event_label: "geotec" });

            loader.hideLoader();
        },
        fill() {
            if (process.env.NODE_ENV == "development") {
                this.email = "dijon@innov-events.fr";
                this.password = "geotec21";
            }
        },
    },
};
</script>
