<template>
    <div class="forgotten-password flex-fill d-flex-center py-3">
        <div v-if="displayModal" class="modal d-flex-center px-2">
            <div style="width: 400px" class="modal__box d-flex-center flex-column rounded p-3">
                <h5 class="text-center">
                    <small>{{ t.forgottenPasswordMsgSuccess }}</small>
                </h5>

                <div class="d-flex align-items-center mt-3">
                    <router-link @click="closeModal()" class="btn btn-geotec mt-3" to="/">
                        {{ t.gotit }}
                    </router-link>
                </div>
            </div>
        </div>

        <div class="form-signin">
            <form @submit.prevent="reinitialize">
                <h1 @click="fill" class="text-center fw-bold h3 my-3 font-weight-normal text-white text-uppercase">
                    {{ t.reinitializePasswordTitle }}
                </h1>

                <input v-model="email" type="text" class="form-control" :placeholder="t.loginEmailAddress" autofocus />

                <p v-if="serverError" class="text-geotec fw-bold">{{ serverErrorMessage }}</p>

                <button v-bind:class="`btn-geotec`" class="btn btn-lg text-white w-100 mt-2">
                    {{ t.reinitializePasswordLink }}
                </button>

                <router-link to="/register" class="my-3 text-white text-decoration-none">
                    {{ t.loginFirstConnectionLink }}
                </router-link>

                <p class="mb-0 text-gray-2 small">&copy; INNOV'events Dijon - {{ new Date().getFullYear() }}</p>
            </form>
        </div>
    </div>
</template>

<script lang="js">
import auth from "../services/auth";
import loader from "../services/loader";
import { t } from '../locale/language'

export default {
    data() {
        return {
            t,
            serverError: false,
            serverErrorMessage: "",
            displayModal: false,
            email: "",
            password:""
        };
    },
    methods: {
        async reinitialize() {
            loader.showLoader();

            if (!this.email) {
                this.serverErrorMessage = this.t.loginInvalidIdMsgError;
                this.serverError = true;
            }
            else {
                await auth.forgottenPassword(this.email, (response) => {
                    if (response.isSuccess) {
                        this.displayModal = true;
                    } else {
                        this.serverErrorMessage = response.errorMessage;
                        this.serverError = true;
                    }
                });
            }

            loader.hideLoader();
        },
        fill() {
            if (process.env.NODE_ENV == "development") {
                this.email = "damien@mod1.fr";
            }
        },
    },
};
</script>
